import HandleTimeline from "./modules/common/timeline.js";

$(document).ready(function () {
  // Chức năng Nút back to top
  var backToTop = $("#back-to-top");

  const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };
  const checkScroll = debounce(() => {
    const scrollTop = $(window).scrollTop();

    if (scrollTop > 450) {
      backToTop.addClass("show");
    } else {
      backToTop.removeClass("show");
    }
  }, 100);

  $(window).scroll(checkScroll);
  backToTop.click(function () {
    $("html, body").animate({ scrollTop: 0 }, "300");
    return false;
  });

  // Chức năng danh mục
  $(".header .header-button").click(function () {
    $(".header-category").toggleClass("active");
    $(".overlay-mega-menu").fadeToggle();

    if ($(".header-category").hasClass("active")) {
      $(".overlay-mega-menu").one("click", function () {
        $(".header-category").removeClass("active");
        $(".overlay-mega-menu").fadeOut();
      });
    }
  });
  function showActiveCategory() {
    var activeCategory = $(".header-category__list li.active").data("category");
    if (activeCategory) {
      $(
        '.header-category__submenu[data-category="' + activeCategory + '"]'
      ).show();
    }
  }
  $(".header-category__list li").hover(function () {
    var category = $(this).data("category");

    $(".header-category__list li.active").removeClass("active");

    $(this).addClass("active");

    $(".header-category__submenu").hide();
    $('.header-category__submenu[data-category="' + category + '"]').show();
  });
  showActiveCategory();

  // Chức năng hiển thị modal thông báo
  $(".header-controls__notification, #btn-notification-mobile").click(function (
    e
  ) {
    $(".modal-notification").addClass("show");
    $("body").addClass("no-scroll");
    $(".overlay-notification")
      .fadeIn()
      .one("click", function () {
        $(".modal-notification").removeClass("show");
        $(".overlay-notification").fadeOut();
        $("body").removeClass("no-scroll");
      });
  });

  // Dropdown shoppe,lazada
  // Hàm để cập nhật trạng thái active cho li
  function updateActiveDropdown() {
    var selectedText = $(".header-search .dropdown-main span").text();
    $(".header-search .dropdown-list li").each(function () {
      if ($(this).text() === selectedText) {
        $(this).addClass("active");
      } else {
        $(this).removeClass("active");
      }
    });
  }
  $(".header-search .dropdown").click(function () {
    $(".header-search .dropdown-list").toggle();
    $(".fa-chevron-down, .fa-chevron-up").toggleClass(
      "fa-chevron-down fa-chevron-up"
    );
    updateActiveDropdown(); // Cập nhật trạng thái active khi mở dropdown
  });
  // Đóng dropdown khi click bên ngoài
  $(document).click(function (e) {
    if (!$(e.target).closest(".dropdown").length) {
      $(".header-search .dropdown-list").hide();
      $(".fa-chevron-up").toggleClass("fa-chevron-down fa-chevron-up");
    }
  });
  // Khi chọn một mục trong dropdown
  $(".header-search .dropdown-list li").click(function (e) {
    e.stopPropagation();
    var selectedText = $(this).text();
    $(".header-search .dropdown-main span").text(selectedText);
    $(".header-search .dropdown-list").hide();
    $(".fa-chevron-up").toggleClass("fa-chevron-down fa-chevron-up");
    updateActiveDropdown(); // Cập nhật trạng thái active sau khi chọn
  });
  // Cập nhật trạng thái active khi chọn
  updateActiveDropdown();

  // Dropdown danh mục
  $(".category-section__header").click(function () {
    var parentSection = $(this).parent();

    if (!parentSection.hasClass("show")) {
      $(".category-section")
        .removeClass("show")
        .find(".category-section__list")
        .slideUp();
      parentSection
        .addClass("show")
        .find(".category-section__list")
        .slideDown();
    } else {
      parentSection
        .removeClass("show")
        .find(".category-section__list")
        .slideUp();
    }
  });

  // Chức năng Carousel
  $("#product-slider").owlCarousel({
    loop: true,
    autoplay: false,
    smartSpeed: 100,
    margin: 10,
    dots: true,
    navText: [
      '<i class="fa-solid fa-chevron-left"></i>',
      '<i class="fa-solid fa-chevron-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
        slideBy: 1,
      },
      374: {
        items: 2,
        slideBy: 2,
      },
      768: {
        items: 3,
        slideBy: 3,
      },
      992: {
        items: 4,
        slideBy: 4,
      },
      1200: {
        items: 6,
        slideBy: 6,
        nav: true,
        dots: false,
        margin: 15,
      },
    },
  });

  $("#filter-mobile-button").click(function (e) {
    $("#category-column").addClass("show");
    $("body").addClass("no-scroll");
    $(".overlay-category")
      .fadeIn()
      .one("click", function () {
        $("#category-column").removeClass("show");
        $("body").removeClass("no-scroll");
        $(".overlay-category").fadeOut();
      });
  });

  // Chức năng carousel
  $("#order-card__banner").owlCarousel({
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 650,
    margin: 20,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
    },
  });

  // Chức năng Slider sản phẩm
  var $mainContainer = $("#myCarousel");
  if ($mainContainer.length) {
    var mainOptions = {
      Dots: false,
    };

    var mainCarousel = new Carousel($mainContainer[0], mainOptions);

    var $navContainer = $("#myNavigation");
    if ($navContainer.length) {
      var navOptions = {
        infinite: false,
        transition: false,
        center: true,
        fill: true,
        slidesPerPage: 4,
        dragFree: true,
        Dots: false,
        Sync: {
          target: mainCarousel,
        },
      };
      new Carousel($navContainer[0], navOptions);
    }
    $(".f-carousel__slide").on("mouseenter", function () {
      $(this).click();
    });
    // Gallery ảnh
    Fancybox.bind("[data-fancybox='gallery']", {
      Toolbar: false,
      animated: false,
      hideScrollbar: false,
      clickContent: function (current, event) {
        return current.type === "image" ? "zoom" : false;
      },
    });
  }

  // Đặt số lượng sản phẩm
  const MAX_QUANTITY = 100;
  function updateMinusButtonState(input) {
    const value = parseInt(input.val(), 10);
    if (value <= 1) {
      input.siblings(".quantity-control__minus").addClass("disabled");
    } else {
      input.siblings(".quantity-control__minus").removeClass("disabled");
    }
  }
  function updatePlusButtonState(input) {
    const value = parseInt(input.val(), 10);
    if (value >= MAX_QUANTITY) {
      input.siblings(".quantity-control__plus").addClass("disabled");
    } else {
      input.siblings(".quantity-control__plus").removeClass("disabled");
    }
  }

  function updateButtonStates(input) {
    updateMinusButtonState(input);
    updatePlusButtonState(input);
  }

  // Sự kiện click cho nút plus
  $(".quantity-control__plus").click(function () {
    const input = $(this).siblings(".quantity-control__value");
    let currentValue = parseInt(input.val(), 10);
    if (currentValue < MAX_QUANTITY) {
      input.val(currentValue + 1);
      updateButtonStates(input);
    }
  });

  // Sự kiện click cho nút minus
  $(".quantity-control__minus").click(function () {
    if (!$(this).hasClass("disabled")) {
      const input = $(this).siblings(".quantity-control__value");
      let currentValue = parseInt(input.val(), 10);
      if (currentValue > 1) {
        input.val(currentValue - 1);
        updateButtonStates(input);
      }
    }
  });

  // Kiểm tra trạng thái ban đầu của nút minus và plus
  updateButtonStates($(".quantity-control__value"));

  // Sự kiện blur cho input số lượng
  $(".quantity-control__value").on("blur", function () {
    let value = parseInt($(this).val(), 10);
    if (isNaN(value) || value < 1) {
      value = 1;
    } else if (value > MAX_QUANTITY) {
      value = MAX_QUANTITY;
    }
    $(this).val(value);
    updateButtonStates($(this));
  });
  // Sự kiện input cho input số lượng để cập nhật trạng thái nút
  $(".quantity-control__value").on("input", function () {
    updateButtonStates($(this));
  });

  // Chức năng ẩn hiện mật khẩu form
  $(".toggle-password").on("click", function () {
    const inputGroup = $(this).closest(".input-group");
    const passwordField = inputGroup.find("input");
    const icon = $(this).find("i");

    const passwordFieldType = passwordField.attr("type");

    if (passwordFieldType === "password") {
      passwordField.attr("type", "text");
      icon.removeClass("fa-eye-slash").addClass("fa-eye");
    } else {
      passwordField.attr("type", "password");
      icon.removeClass("fa-eye").addClass("fa-eye-slash");
    }
  });

  // Xác nhận form đăng nhập
  $("#login-page form").validate({
    rules: {
      email: {
        required: true,
        email: true,
      },
      password: {
        required: true,
        minlength: 6,
      },
    },
    messages: {
      email: {
        required: "Vui lòng nhập địa chỉ email",
        email: "Vui lòng nhập một địa chỉ email hợp lệ",
      },
      password: {
        required: "Vui lòng nhập mật khẩu",
        minlength: "Mật khẩu của bạn phải có ít nhất 6 ký tự",
      },
    },
    errorPlacement: function (error, element) {
      var errorMessageText = error.text();
      element
        .closest(".form-group")
        .find(".message-invalid")
        .text(errorMessageText);
    },
    highlight: function (element, errorClass) {
      $(element)
        .closest(".form-group")
        .find(".message-invalid")
        .css("display", "block");
    },
    unhighlight: function (element, errorClass) {
      $(element)
        .closest(".form-group")
        .find(".message-invalid")
        .css("display", "none");
    },
  });

  // Xác nhận form đăng ký
  $("#register-page form").validate({
    rules: {
      fullname: {
        required: true,
        minlength: 2,
      },
      username: {
        required: true,
        minlength: 4,
      },
      phone: {
        required: true,
        digits: true,
        minlength: 10,
        maxlength: 11,
      },
      email: {
        required: true,
        email: true,
      },
      password: {
        required: true,
        minlength: 6,
      },
      terms: {
        required: true,
      },
    },
    messages: {
      fullname: {
        required: "Vui lòng nhập họ và tên của bạn",
        minlength: "Họ và tên của bạn phải có ít nhất 2 ký tự",
      },
      username: {
        required: "Vui lòng nhập tên đăng nhập",
        minlength: "Tên đăng nhập phải có ít nhất 4 ký tự",
      },
      phone: {
        required: "Vui lòng nhập số điện thoại",
        digits: "Số điện thoại phải là số",
        minlength: "Số điện thoại phải có ít nhất 10 ký tự",
        maxlength: "Số điện thoại không được vượt quá 11 ký tự",
      },
      email: {
        required: "Vui lòng nhập địa chỉ email",
        email: "Vui lòng nhập một địa chỉ email hợp lệ",
      },
      password: {
        required: "Vui lòng nhập mật khẩu",
        minlength: "Mật khẩu của bạn phải có ít nhất 6 ký tự",
      },
      terms: {
        required: "Bạn phải đồng ý với điều khoản dịch vụ",
      },
    },
    errorPlacement: function (error, element) {
      var errorMessageText = error.text();
      element
        .closest(".form-group, .form-check")
        .find(".message-invalid")
        .text(errorMessageText);
    },
    highlight: function (element, errorClass) {
      $(element)
        .closest(".form-group, .form-check")
        .find(".message-invalid")
        .css("display", "block");
    },
    unhighlight: function (element, errorClass) {
      $(element)
        .closest(".form-group, .form-check")
        .find(".message-invalid")
        .css("display", "none");
    },
  });

  // Chức năng danh mục cho màn mobile
  $(".category-tab button").on("click", function () {
    var category = $(this).data("category");

    $(".category-tab button").removeClass("active");
    $(this).addClass("active");

    $(".category-content__submenu").hide();
    $('.category-content__submenu[data-category="' + category + '"]').show();
  });
  $(".category-tab button.active").trigger("click");

  // Thay đổi Placeholder search tìm kiếm
  function updatePlaceholder() {
    var input = document.getElementById("searchInput");
    if (input) {
      // Kiểm tra nếu phần tử tồn tại
      if (window.innerWidth <= 599) {
        // Kích thước màn hình nhỏ hơn hoặc bằng 768px (mobile)
        input.setAttribute("placeholder", "Tìm kiếm sản phẩm...");
      } else {
        input.setAttribute(
          "placeholder",
          "Tìm kiếm bằng link hoặc tên sản phẩm"
        );
      }
    }
  }
  window.onload = updatePlaceholder;
  window.onresize = updatePlaceholder;

  // Chọn kích thước
  $(".size-options .option").on("click", function () {
    $(".size-options .option").removeClass("active");
    $(this).addClass("active");

    var selectedSize = $(this).text();
    console.log("Kích thước đã chọn: " + selectedSize);
  });

  // Chọn màu sắc
  $(".color-options .option").on("click", function () {
    $(".color-options .option").removeClass("active");
    $(this).addClass("active");

    var selectedColor = $(this).text();
    console.log("Màu sắc đã chọn: " + selectedColor);
  });

  // Select address default
  $("#defaultAddressToggle").on("change", function () {
    if ($(this).is(":checked")) {
      console.log("Toggle is ON");
    } else {
      console.log("Toggle is OFF");
    }
  });

  $(".payment-option").on("click", function () {
    // Loại bỏ class active từ tất cả các tùy chọn
    $(".payment-option").removeClass("active");
    // Thêm class active cho tùy chọn đã nhấn
    $(this).addClass("active");

    // Ẩn tất cả các nội dung thanh toán
    $(".payment-methods__content").removeClass("active");

    // Hiển thị nội dung tương ứng
    if ($(this).attr("id") === "bankTransfer") {
      $("#bankContent").addClass("active");
    } else if ($(this).attr("id") === "wallet") {
      $("#walletContent").addClass("active");
    }
  });
  $("#cart-page .alert-close").click(function (e) {
    $(this).parent().remove();
  });

  // Dropdown lịch sử giao dịch
  $(".dropdownTrade").click(function () {
    $(this).find(".dropdownTrade-list").toggle();
  });
  $(".dropdownTrade-list__item").click(function (e) {
    e.stopPropagation();

    var selectedText = $(this).text();

    $(this)
      .closest(".dropdownTrade")
      .find(".dropdownTrade-main")
      .text(selectedText);

    $(this).siblings().removeClass("selected");

    $(this).addClass("selected");

    $(this).closest(".dropdownTrade-list").hide();
  });
  $(document).click(function (e) {
    if (!$(e.target).closest(".dropdownTrade").length) {
      $(".dropdownTrade-list").hide();
    }
  });

  // Tooltip
  $('[data-bs-toggle="tooltip"]').tooltip();

  // Validate the form
  $.validator.addMethod(
    "checkPasswordFormat",
    function (value, element) {
      return (
        this.optional(element) ||
        (/[A-Za-z]/.test(value) && /[0-9]/.test(value))
      );
    },
    "Mật khẩu phải bao gồm cả chữ và số."
  );
  $("#changePasswordForm").validate({
    rules: {
      current_password: {
        required: true,
      },
      new_password: {
        required: true,
        minlength: 8,
        maxlength: 32,
        checkPasswordFormat: true, // Use custom method here
      },
      confirm_password: {
        required: true,
        equalTo: "#new-password",
      },
    },
    messages: {
      current_password: {
        required: "Vui lòng nhập mật khẩu hiện tại.",
      },
      new_password: {
        required: "Vui lòng nhập mật khẩu mới.",
        minlength: "Mật khẩu phải dài ít nhất 8 ký tự.",
        maxlength: "Mật khẩu không được dài quá 32 ký tự.",
        checkPasswordFormat: "Mật khẩu phải bao gồm cả chữ và số.",
      },
      confirm_password: {
        required: "Vui lòng nhập lại mật khẩu mới.",
        equalTo: "Mật khẩu không trùng khớp.",
      },
    },
    errorPlacement: function (error, element) {
      element
        .closest(".form-group")
        .find(".message-invalid")
        .text(error.text());
    },
    highlight: function (element) {
      $(element)
        .closest(".form-group")
        .find(".message-invalid")
        .css("display", "block");
    },
    unhighlight: function (element) {
      $(element)
        .closest(".form-group")
        .find(".message-invalid")
        .css("display", "none");
    },
  });

  // Xử lý sự kiện click trên header của accordion
  $(".accordion-item__header").click(function () {
    if ($(this).next(".accordion-item__content").length > 0) {
      $(".accordion-item__content").not($(this).next()).slideUp();
      $(".accordion-item__header i")
        .not($(this).find("i"))
        .removeClass("rotate");

      $(this).next(".accordion-item__content").slideToggle();

      $(this).find("i").toggleClass("rotate");
    }
  });
  $(".accordion-item__header").each(function () {
    if ($(this).next(".accordion-item__content").length === 0) {
      $(this).find("i.fa-chevron-down").remove();
    }
  });

  // Focus input search khi modal show
  $("#searchModal").on("shown.bs.modal", function () {
    setTimeout(function () {
      $(".search-field input").focus();
    }, 300); // Trì hoãn 500ms để đảm bảo modal đã hiển thị
  });

  // Timeline
  HandleTimeline();

  //  Loadmore Product
  const defaultItems = 20;
  const itemsPerLoad = 5;
  const apiUrl =
    "https://my-json-server.typicode.com/khangdev1998/hangthai-product/products";
  let allProducts = []; // Lưu trữ tất cả sản phẩm sau khi tải từ API
  let loadedItems = 0;

  // Hàm để hiển thị sản phẩm
  function displayProducts(products) {
    products.forEach((product) => {
      $("#new-product").append(`
      <div class="product-item">
          <div class="product-item__image">
              <img src="${product.image_url}" alt="this-image">
          </div>
          <div class="product-item__content">
              <div class="d-flex flex-column gap-2">
                  <h1>
                      <a href="chi-tiet-san-pham.html">
                          <img class="me-1" src="${product.icon_url}" alt="this-icon">
                          ${product.title}
                      </a>
                  </h1>
                  <div class="d-flex flex-column gap-1">
                      <span>${product.price}</span>
                      <b>${product.rating}</b>
                  </div>
                  <p>${product.sold}</p>
              </div>
          </div>
      </div>
    `);
    });
  }

  // Tải tất cả sản phẩm từ API
  $.ajax({
    url: apiUrl,
    method: "GET",
    success: function (data) {
      if (Array.isArray(data) && data.length > 0) {
        allProducts = data;
        // Hiển thị 20 sản phẩm đầu tiên
        const initialProducts = allProducts.slice(0, defaultItems);
        displayProducts(initialProducts);
        loadedItems = defaultItems;
      } else {
        console.error("No products found or data is not an array.");
      }
    },
    error: function (error) {
      console.error("Error fetching data", error);
    },
  });
  // Xử lý khi nhấn nút "Xem thêm"
  $(".btn-more").click(function () {
    const nextProducts = allProducts.slice(
      loadedItems,
      loadedItems + itemsPerLoad
    );
    displayProducts(nextProducts);
    loadedItems += itemsPerLoad;
  });

  // Chọn các mệnh giá nạp tiền
  $(".amount-option").on("click", function () {
    // Xóa class selected từ các lựa chọn khác và thêm class selected vào lựa chọn hiện tại
    $(".amount-option").removeClass("selected");
    $(this).addClass("selected");

    // Lấy giá trị mệnh giá đã chọn
    const selectedAmountText = $(this).find(".amount-price").text().trim();
    let selectedAmount = parseInt(selectedAmountText.replace(/[.,đ]/g, ""));

    // Kiểm tra nếu mệnh giá 10,000,000đ để thêm 200k ưu đãi
    let bonusAmount = 0;
    let bonusText = "";
    if (selectedAmount === 10000000) {
      bonusAmount = 200000;
      // Thêm dấu + vào trước số tiền ưu đãi
      bonusText = "+" + bonusAmount.toLocaleString("vi-VN") + "đ";
      // Đổi màu chữ của bonus-amount thành #575fcc
      $("#bonus-amount").css("color", "#575fcc");
    } else {
      // Đặt lại màu mặc định và không có dấu +
      bonusText = bonusAmount.toLocaleString("vi-VN") + "đ";
      $("#bonus-amount").css("color", "");
    }

    // Cập nhật giá trị ở phần Tóm tắt thanh toán
    $("#deposit-amount").text(selectedAmount.toLocaleString("vi-VN") + "đ");
    $("#bonus-amount").text(bonusText);
    const totalAmount = selectedAmount; // Tổng tiền không bao gồm bonus
    $("#total-amount").text(totalAmount.toLocaleString("vi-VN") + "đ");

    // Kiểm tra điều kiện để loại bỏ class btn-disabled khỏi nút Nạp ngay
    if ($(".amount-option.selected").length > 0) {
      $(".btn-primary").removeClass("btn-disabled");
    }
  });

  // Count down transaction
  var initialMinutes = parseInt($("#minutes").text(), 10);
  var initialSeconds = parseInt($("#seconds").text(), 10);

  // Tính tổng số giây từ phút và giây ban đầu
  var totalSeconds = initialMinutes * 60 + initialSeconds;

  // Hàm định dạng số thành 2 chữ số
  function formatNumber(number) {
    return number < 10 ? "0" + number : number;
  }

  // Hàm cập nhật đồng hồ đếm ngược
  function updateCountdown() {
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = totalSeconds % 60;

    // Cập nhật hiển thị trên giao diện
    $("#minutes").text(formatNumber(minutes));
    $("#seconds").text(formatNumber(seconds));

    // Giảm tổng số giây
    totalSeconds--;

    // Nếu thời gian kết thúc, dừng đếm ngược
    if (totalSeconds < 0) {
      clearInterval(countdownTimer);
      alert("Giao dịch đã hết thời gian!");
    }
  }
  // Thiết lập hàm updateCountdown chạy mỗi giây
  var countdownTimer = setInterval(updateCountdown, 1000);

  // Form validation forgot password
  $("#forgot-password").validate({
    rules: {
      email: {
        required: true,
        email: true,
      },
    },
    messages: {
      email: {
        required: "Vui lòng nhập địa chỉ email",
        email: "Vui lòng nhập một địa chỉ email hợp lệ",
      },
    },
    errorPlacement: function (error, element) {
      var errorMessageText = error.text();
      element
        .closest(".form-group")
        .find(".message-invalid")
        .text(errorMessageText);
    },
    highlight: function (element, errorClass) {
      $(element)
        .closest(".form-group")
        .find(".message-invalid")
        .css("display", "block");
    },
    unhighlight: function (element, errorClass) {
      $(element)
        .closest(".form-group")
        .find(".message-invalid")
        .css("display", "none");
    },
  });

  // Form validation reset password
  $("#reset-password").validate({
    rules: {
      password: {
        required: true,
        minlength: 6,
      },
      "confirm-password": {
        required: true,
        equalTo: "#password",
      },
    },
    messages: {
      password: {
        required: "Vui lòng nhập mật khẩu",
        minlength: "Mật khẩu của bạn phải có ít nhất 6 ký tự",
      },
      "confirm-password": {
        required: "Vui lòng nhập lại mật khẩu.",
        equalTo: "Mật khẩu nhập lại không khớp.",
      },
    },
    errorPlacement: function (error, element) {
      var errorMessageText = error.text();
      element
        .closest(".form-group")
        .find(".message-invalid")
        .text(errorMessageText);
    },
    highlight: function (element, errorClass) {
      $(element)
        .closest(".form-group")
        .find(".message-invalid")
        .css("display", "block");
    },
    unhighlight: function (element, errorClass) {
      $(element)
        .closest(".form-group")
        .find(".message-invalid")
        .css("display", "none");
    },
  });
});
